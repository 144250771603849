import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from '../landingPage/Navbar';
import Home from '../landingPage/Home';
import Footer from '../landingPage/Footer';
import ScrollToTop from '../utils/ScrollToTop';
import { Analytics } from '@vercel/analytics/react';
import './App.css';

const Plans = lazy(() => import('../landingPage/Plans'));
const Instructors = lazy(() => import('../landingPage/Instructors'));
const Team = lazy(() => import('../landingPage/Team'));
const Associations = lazy(() => import('../landingPage/Associations'));
const Contact = lazy(() => import('../landingPage/Contact'));
const PageNotFound = lazy(() => import('../landingPage/PageNotFound'));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Analytics />
      <div className="App">
        <Navbar />
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/plans" element={<Plans />} />
            <Route path="/instructors" element={<Instructors />} />
            <Route path="/team" element={<Team />} />
            <Route path="/associations" element={<Associations />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
