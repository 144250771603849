import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-blue-950 text-white h-[56px] flex items-center justify-center">
      <div className="text-sm text-center">
        &copy; {new Date().getFullYear()} HOBON BIKE SCHOOL. All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;
