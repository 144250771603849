import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Component for a normal link
const NormalLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="text-white hover:text-blue-300 transition duration-300 ease-in-out"
    onClick={onClick}
  >
    {children}
  </Link>
);

// Component for a dropdown link
const DropdownLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="block text-white hover:bg-blue-700 px-3 py-2 rounded-md text-base font-medium"
    onClick={onClick}
  >
    {children}
  </Link>
);

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // List of routes
  const routes = [
    { path: '/', label: 'Inicio' },
    { path: '/plans', label: 'Planes de Entrenamiento' },
    { path: '/instructors', label: 'Instructores' },
    { path: '/team', label: 'Equipo' },
    { path: '/associations', label: 'Asociaciones' },
    { path: '/contact', label: 'Contacto' },
  ];

  return (
    <nav className="bg-blue-950 p-3 fixed top-0 w-full z-10">
      <div className="container mx-auto flex justify-between items-center">
        <img
          src={require('../assets/logo.webp')}
          alt="Logo"
          className="h-8 w-auto"
          loading="lazy"
        />

        {/* Hamburger Icon */}
        <div className="lg:hidden" onClick={toggleMenu}>
          <button className="text-white focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        {/* Links for large screens */}
        <div className="hidden lg:flex space-x-4">
          {routes.map((route) => (
            <NormalLink key={route.path} to={route.path}>
              {route.label}
            </NormalLink>
          ))}
        </div>
      </div>

      {/* Mobile menu */}
      {isOpen && (
        <div className="lg:hidden">
          <div className="py-6 pb-3 space-y-1 sm:px-3">
            {routes.map((route) => (
              <DropdownLink
                key={route.path}
                to={route.path}
                onClick={closeMenu}
              >
                {route.label}
              </DropdownLink>
            ))}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
