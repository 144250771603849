import React from 'react';

const Home = () => {
  return (
    <div className="">
      <div className="relative w-full h-[calc(100vh-56px)] overflow-hidden">
        <img
          src={require('../assets/front_page.webp')}
          alt="Background"
          className="w-full h-full object-cover object-center"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="text-center text-white px-4">
            <h1 className="text-6xl md:text-7xl font-semibold">
              Hobon Bike School
            </h1>
            <h2 className="text-2xl">Técnica y buenos momentos</h2>
          </div>
        </div>
      </div>

      {/* Space for buttons and images */}
      <div className="max-w-5xl mx-auto py-12 flex flex-col gap-12 px-6 lg:px-0">
        <div className="flex flex-col items-center text-center gap-8">
          {/* <h2 className="text-5xl font-semi-bold">La mejor escuela. La mejor comunidad.</h2> */}
          <p className="md:text-xl text-gray-900 text-justify">
            Nuestra escuela es la{' '}
            <strong className="text-blue-800">elección perfecta</strong> para
            los amantes del MTB. Nos enorgullece ser líderes en la enseñanza
            técnica, proporcionando un espacio donde cada alumno/a puede{' '}
            <strong className="text-blue-800">
              perfeccionar sus habilidades
            </strong>
            . Pero no solo se trata de la técnica; valoramos los momentos
            compartidos y las experiencia que nuestros alumnos viven en cada
            ruta. Aquí, la{' '}
            <strong className="text-blue-800">comunidad y la diversión</strong>{' '}
            en el camino son tan importantes como el aprendizaje, creando una{' '}
            <strong className="text-blue-800">experiencia inolvidable</strong>{' '}
            en el mundo del mountain bike.
          </p>
        </div>

        <hr className="border-t-2 border-gray-400 my-2" />

        <div className="flex flex-col items-center text-center gap-8">
          <h2 className="text-4xl text-center text-gray-800 font-semibold">
            ¡Forma tu propio grupo de clases!
          </h2>
          <p className="md:text-xl text-gray-900 text-justify">
            <strong className="text-blue-800">Invita a tus amigos</strong> y
            forma tu propio grupo de clases de MTB. Disfruta de sesiones
            personalizadas y comparte la emoción del mountain bike con tu grupo.{' '}
            <strong className="text-blue-800">Conoce mas sobre nosotros</strong>{' '}
            y comienza esta experiencia inolvidable.
          </p>
        </div>

        <div className="flex flex-col md:flex-row justify-center gap-12">
          <div className="flex flex-col items-center gap-2">
            <img
              src={require('../assets/home/courses.jpg')}
              alt="entrenamientos"
              className="object-cover"
              loading="lazy"
            />
            <a
              href="/plans"
              className="mt-2 bg-blue-900 hover:bg-blue-950 text-white font-bold py-2 px-4 w-full text-center rounded"
            >
              Planes de entrenamiento
            </a>
          </div>

          <div className="flex flex-col items-center gap-2">
            <img
              src={require('../assets/home/instructors.jpg')}
              alt="instructores"
              className="object-cover"
              loading="lazy"
            />
            <a
              href="/instructors"
              className="mt-2 bg-blue-900 hover:bg-blue-950 text-white font-bold py-2 px-4 w-full text-center rounded"
            >
              Instructores
            </a>
          </div>

          <div className="flex flex-col items-center gap-2">
            <img
              src={require('../assets/home/asociations.jpg')}
              alt="asociaciones"
              className="object-cover"
              loading="lazy"
            />
            <a
              href="/associations"
              className="mt-2 bg-blue-900 hover:bg-blue-950 text-white font-bold py-2 px-4 w-full text-center rounded"
            >
              Asociaciones
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
